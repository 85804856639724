/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';

import { IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const SelectStationDropDown = ({ list, slideOpen, setOpenStationDropDown, setStationName, title }) => {

    const [name, setName] = useState("");
    const [stationArray, setStation] = useState([]);
    const [selectedStation, setSelectedStation] = useState();

    const stationNameRef = useRef(null);

    useEffect(() => {
        if (name != "") {
            let sortedStationList = [];
            for (const item of list) {
                let isStationName = item.stationname.toLowerCase().includes(name.toLocaleLowerCase());
                let isStationCode = item.stationcode.toLowerCase().includes(name.toLocaleLowerCase());
                if (isStationName || isStationCode) {
                    sortedStationList.push(item)
                }
            }
            setStation([...sortedStationList]);
        }
        if (name == "") setStation([...list])
    }, [name]);

    // store all station names namesArray.
    useEffect(() => {
        setStation([...list]);
    }, [list]);

    useEffect(() => {
        if (selectedStation) {
            setStationName(selectedStation);
            setOpenStationDropDown(false)
        }
    }, [selectedStation]);

    useEffect(() => {
        if (slideOpen)
            stationNameRef.current.focus();
    }, [slideOpen])



    return (
        <div style={{ position: 'fixed', top: "0", bottom: "0", right: "0", left: "0", background: "#80808078", display: slideOpen ? "" : "none" }} >
            <div style={{ position: "absolute", top: "70px", bottom: "0", right: "0", left: "0", margin: "auto", maxWidth: "640px", borderRadius: "16px 16px 0 0", background: "#FFF", display: slideOpen ? "" : "none" }}>
                <div className={"sliderStationList"} id='slider-container'>
                    <Paper id="slider-paper" style={{ height: slideOpen ? "100%" : "0%" }} >
                        <div style={{ padding: "16px", paddingTop: "0", height: "100%" }} >

                            <div style={{ margin: "auto", width: "48px", height: "4px", background: "#DDD", borderRadius: "8px" }} />

                            <div className={"selectStationSliderHeader"}>
                                <Typography children={title} className='headerText' id="headerText" />
                                <IconButton onClick={() => { setName(""); setOpenStationDropDown(false); }}> <CloseIcon style={{ color: "#1A1A1A" }} /> </IconButton>
                            </div>

                            <TextField
                                inputRef={stationNameRef}
                                id="station-name-input"
                                fullWidth
                                placeholder='Cari kota atau stasiun'
                                onChange={(e) => setName(e.target.value)}
                                autoComplete={"off"}
                                value={name}
                                InputProps={{
                                    style: { height: "40px", borderRadius: "8px" },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ color: "#3D4A5B" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <div className={"stationlistContainer"}>
                                {
                                    stationArray.map(({ stationname, title, stationcode, id }, i) =>
                                        <div key={`station-card-container-${stationcode}-${id}`} className={"stationNameBlock"} onClick={() => { setSelectedStation(stationArray[i]) }} >
                                            <div>
                                                <Typography children={stationname} className={"stationName"} />
                                                <Typography children={title} style={{ fontSize: "12px", color: "#1A1A1A" }} />
                                            </div>
                                            <Typography children={stationcode} className={"stationStatus"} />
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default SelectStationDropDown;