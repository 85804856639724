import { createTheme } from "@mui/material/styles"

const palette = {
  blue: {
    main: '#00BFB2'
  }
}
const typography = {
  fontFamily: `'DM Sans', sans-serif`
}

const theme = createTheme({
  palette: palette,
  typography,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
            "&.Mui-focused fieldset": {
              borderColor: palette.blue.main,
            },
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23) !important', // set the hover border color to initial
          },
          '&.Mui-focused fieldset': {
            borderColor: palette.blue.main + '!important', // set the focused border color to blue
          },
        },
      },
    },
  }
})

export default theme
