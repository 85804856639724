/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { getWagonClass } from '../../common/Utils';

const PassengerDetailCards = ({ seatChange }) => {
    const [passengers, setPassengers] = useState([]);
    const KAI = JSON.parse(localStorage.getItem('KAI'));

    useEffect(() => {
        setPassengers([KAI.seatList.currentSeat]);
    }, []);

    return (
        <div style={{ background: "#F7F6F6", display: "flex", alignItems: "center" }} >
            {passengers.map(({ idnumber, name, wagonclasscode, stamformdetcode, wagondetrow, wagondetcol, passengertype }, i) =>
                passengertype == "A" &&
                <div style={{ padding: "16px" }} key={`passenger-container-${idnumber
                    }`} id={`passengerCard-${i}`}>
                    <div className={`${"displayFlex"} ${"cardStyle"}`} id="passenger-card"
                        style={{ border: seatChange ? '1px solid #2196f3' : '1px solid #DDD' }}
                    >
                        <Typography className={"passengerIndex"} >{i + 1}</Typography>
                        <div style={{ marginLeft: "12px" }}>
                            <div className={"displayFlex"}>
                                <Typography id={`passengerName-${i}`} className={"passengerCardPrimaryInfo"} children={name} />
                                <CheckCircleRoundedIcon color='success' style={{ height: "1rem", marginLeft: "10px", visibility: seatChange ? "" : "hidden" }} />
                            </div>
                            <Typography id={`passengerClass-${i}`} className={"passengerCardSecondaryInfo"} children={`${getWagonClass(stamformdetcode.split('-')[0], stamformdetcode.split('-')[1])}`} />
                            <Typography id={`passengerSeat-${i}`} className={"passengerCardPrimaryInfo"} children={`${wagondetrow}${wagondetcol}`} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PassengerDetailCards