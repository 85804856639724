/* eslint-disable */
import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
    carouselNavButtons: {
      padding: '5px',
      backgroundColor: "#00BFB2",
      margin: "5px auto",
      '&:hover': {
        opacity: "1!important",
        backgroundColor: "#00BFB2 !important",
        filter: "brightness(100%) !important"
      }
    }
})

export const initSeatLayout = (props) => {
    const {getWagonClass, setCurrentPassengerIndex, setPassengerList, setClassSeatArray} = props
    const KAI = JSON.parse(localStorage.getItem('KAI'));

    const currentTicketNumber = KAI.seatList.currentSeat.ticketnum;
    const passengerListArray = [];

    // CREATE LIST OF PASSENGERS DEFAULT ALLOCATED SEAT...... TO PLACE NUMBERS ON SEAT BLOCK BY COMPARING
    KAI.seatList.paxes.map(({ stamformdetcode, wagondetrow, wagondetcol, ticketnum }, i) => {
      const wagonClassName = getWagonClass(stamformdetcode.split('-')[0], stamformdetcode.split('-')[1])
      passengerListArray.push(wagonClassName + ',' + wagondetrow + wagondetcol);

      // SET THE INDEX OF SELECTED PASSENGER TO PLACE ON SEAT BLOCK
      if (currentTicketNumber == ticketnum) setCurrentPassengerIndex(i)
    });
    setPassengerList(passengerListArray);
    // SETS NO OF PAGES TO SWIPE/CHANGE
    setClassSeatArray(KAI.seatList.list.classSeatData);

    if (KAI.seatList?.newSelectedSeat) {
      KAI.seatList.newSelectedSeat = "";
      localStorage.setItem("KAI", JSON.stringify(KAI));
    }
}

export const calculateHeight = (props) => {
    const {setHeight, currentSelectedClassLayout, setNoOfRows} = props
    setHeight((currentSelectedClassLayout.rows) * (32 + 24) - 12); // height + marginBottom of each row

    const rows = [];
    for (let index = 0; index < currentSelectedClassLayout.rows; index++) {
        rows.push(index);
    }
    const available = currentSelectedClassLayout.classSeatAvailable;
    const notAvailable = currentSelectedClassLayout.classSeatUnAvailable;
    setNoOfRows({ rows, available, notAvailable });
}

export const commonStyle = { width:"32px", height:"32px" }

export const seatColumnStyle = { ...commonStyle, textAlign:"center", marginRight:"16px", fontWeight:500, fontFamily: `'DM Sans', sans-serif` }

export const seatRowIndexStyle = { height: '32px', width: '32px', padding: 5, marginRight: 10, textAlign: 'center', fontWeight: 500, fontFamily: `'DM Sans', sans-serif` }

export const getBackground = (passengerList, classSeatAvailableList, classSeatNotAvailableList, row_column, seat) => {

    // IF THE SEAT => ROW+COLUMN IS INCLUDED IN SEAT UNAVAILABLE RETURN WHITE TO DISPLAY AS BLANK
    if (classSeatNotAvailableList.includes(row_column)) return "#FFF";

    // IF SEAT NOT IN UNAVAILABLE SEAT BUT EXISTS IN SELECTED PASSENGER SEAT LIST SEND WHITE TO PUT BORDER AND NUMBER
    if (passengerList.includes(seat)) return "#FFF";

    // IF SEAT AVAILABLE TO BOOK
    if (classSeatAvailableList.includes(row_column)) return "#00BFB2";
    else return "#B7B7B7";
}

export const getBorder = (passengerList, seat) => {
    // IF THE SEAT IS SAME AS ELEMENT PRESENT IN PASSENGER LIST RETURN BORDER 
    if (passengerList.includes(seat)) return "1px solid #00BFB2";
    else return "0px";
}

export const handleSeatClick = (props) => {
    const {
        passengerList, 
        selectedClass, 
        setPassengerList, 
        setSeatChange, 
        seat, 
        passengerIndex, 
        classSeatAvailableList
    } = props;

    if (classSeatAvailableList.includes(seat)) {
      let list = [...passengerList];
      let existingSeat = list[passengerIndex].split(',')[0];
      if (existingSeat !== selectedClass) existingSeat = selectedClass;
      list[passengerIndex] = existingSeat + "," + seat;

      setPassengerList(list)
      const KAI = JSON.parse(localStorage.getItem('KAI'));
      const newSelectedSeat = list[passengerIndex]
      KAI.seatList.newSelectedSeat = newSelectedSeat;
      localStorage.setItem('KAI', JSON.stringify(KAI));

      setSeatChange(true);
    }
}

export const getPassengerIndex = (passengerList, seat) => {
    for (let index = 0; index < passengerList.length; index++) {
        if (passengerList[index] == seat) return String(index + 1);
    }
    return '';
}
